import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';

/*
 * 删除视频接口
 * @param openid 用户 openid
 * @param orderid 订单号
 * @returns {Promise<boolean>} 成功返回 true，失败返回 false
 
 */
export const deleteVideo = async (openid, orderid) => {
    const confirmResult = await ElMessageBox.confirm(
        '确认删除该视频吗？',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    );
    console.log("openid:" + openid + "orderid:" + orderid);

    if (confirmResult) {
        try {
            const response = await axios.post(
                "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/delorder/uniacid/10004&openid=" + openid + "&orderid=" + orderid
            );
            console.log(response);
            if (response.status === 200) {
                ElMessage({ type: "success", message: "删除成功" });
                return true; // 成功返回 true
            } else {
                ElMessage({ type: "error", message: "删除失败" });
                return false; // 失败返回 false
            }
        } catch (error) {
            console.error(error);
            ElMessage({ type: "error", message: "删除失败" });
            return false; // 捕获异常返回 false
        }
    }
    return false; // 用户取消操作
};

/*
 * 获取推荐视频接口
 * @param page 页码
 * @returns {Promise<boolean>} 成功返回推荐视频数据，失败返回 false
 * 
 * 成功返回数据格式：
 * {
 *  "id": 4798, //视频id
 *  "orderid": "9362",//订单id
 *  "videopath_ol": "http://cdn.duomiapi.com/attachments/video/runway/20240911/0bc3be10bcb8df56b8ea2a73e871483d.mp4",//视频url
 * 
 
 */

export const getRecommendVideo = async (page) => {
    try {
        const response = await axios.get(
            "/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/new_n/uniacid/10004&page=" + page
        );
        console.log(response);
        if (response.status === 200) {
            return response.data.data; // 成功返回推荐视频数据
        } else {
            ElMessage({ type: "error", message: "获取推荐视频失败" });
            return false; // 失败返回 false
        }
    } catch (error) {
        console.error(error);
        ElMessage({ type: "error", message: "获取推荐视频失败" });
        return false; // 捕获异常返回 false
    }
};



/**
 * 提交表单数据并处理登录或注册
 * @param {string} username 用户名
 * @param {string} password 密码
 * @returns {Promise<object>} 返回包含登录或注册结果的对象
 */

export const submitLoginForm = async (username, password) => {
    try {
        const response = await axios.post(
            `/genceai/?s=/api/api/run/m/xz_aivideo/do/adduser/uniacid/10004&username=${username}&password=${password}`
        );
        console.log(username, password);
        console.log(response.data.data);

        if (response.data.message === "登录成功" || response.data.message === "注册成功") {
            if (response.data.message === "登录成功") {
                const openid = response.data.data.openid;
                console.log(openid);

                try {
                    const userInfoResponse = await axios.get(
                        `/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10004&openid=${openid}`
                    );
                    console.log(userInfoResponse.data.data);
                    return {
                        isLoggedIn: true,
                        openid: openid,
                        drawNum: userInfoResponse.data.data.drawNum,
                        message: "登录成功",
                    };
                } catch (userInfoErr) {
                    console.log(userInfoErr);
                    throw userInfoErr;
                }
            } else {
                // 注册成功的逻辑提示
                return {
                    isLoggedIn: false,
                    message: "注册成功，请登录"
                };
            }
        } else {
            throw new Error("密码错误，请重新输入");
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};




/**
 * 获取用户信息
 * @param {string} openid 用户的唯一标识符
 * @returns {Promise<object>} 包含用户信息的 Promise 对象
 */
export const getUserInfo = async (openid) => {
  try {
    const response = await axios.get(
      `/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/userinfo/uniacid/10004&openid=${openid}`
    );
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Failed to fetch user info:', error);
    throw error;
  }
};


/**
 * 激活码处理逻辑
 * @param {string} code 激活码
 * @param {string} openid 用户的唯一标识符
 * @returns {Promise<object>} 包含激活结果的 Promise 对象
 */
export const activateCode = async (code, openid) => {
  try {
    const response = await axios.post(
      `/genceai/?s=/api/api/run/m/xz_aivideo/do/checkcode/uniacid/10004&code=${code}&openid=${openid}`
    );
    console.log(response);
    console.log("激活码:", code);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};




/**
 * 获取用户的视频列表
 * @param {string} openid 用户的唯一标识符
 * @param {number} currentPage 当前页码
 * @param {Set} requestedMusicIds 已请求的 musicid 集合
 * @param {Array} deng 绘画中的订单列表
 * @param {Ref<Array>} musicUrl 响应式视频 URL 列表
 * @returns {Promise<void>}
 */
export const getUserVideoList = async (openid, currentPage, requestedMusicIds, deng, musicUrl) => {
  try {
    console.log("CurrentPage:", currentPage);

    // 获取用户的订单列表（需要传递 openid 和 page 参数，一次请求 6 条数据）
    const response = await axios.get(`/genceai/index.php?s=/api/api/run/m/xz_aivideo/do/orderlist/uniacid/10004&openid=${openid}&page=${currentPage}`);
    console.log(response.data.data);
    const videoList = response.data.data;

    let isB = false;
    let isD = false;

    // 更新绘画中的订单列表
    for (let item of videoList) {
      if (item.state === 0) {
        isB = true;
      }
    }

    for (let item of videoList) {
      if (item.state === 0) {
        if (!deng.some(existingItem => existingItem?.id === item.id)) {
          deng.push(item);
          console.log("绘画中的订单:", deng);
        }
      } else if (item.state === 1) {
        deng = deng.filter(existingItem => existingItem?.id !== item.id);
        console.log("绘画已完成，更新后的订单:", deng);
      }
    }

    console.log("绘画中的订单：", deng);
    console.log("绘画中的订单数量：", deng.length);

    // 请求视频信息
    for (const video of videoList) {
      console.log("videoid:", video?.musicid);

      if (!requestedMusicIds?.has(video?.musicid) && video?.state === 1) {
        if (requestedMusicIds) {
          requestedMusicIds.add(video.musicid);
        }

        const musicResponse = await axios.get(`/genceai/?s=/api/api/run/m/xz_aivideo/do/showmusic/uniacid/10004/id/${video.musicid}`);
        const videoInfo = {
          id: video?.id,
          videopath_ol: musicResponse.data?.data?.videopath_ol,
          title: musicResponse.data?.data?.title,
          userid: musicResponse.data?.data?.userid,
          prompt: musicResponse.data?.data?.orderobj?.prompt?.slice(1),
          orderid: musicResponse.data?.data?.orderobj?.id
        };

        console.log(videoInfo);
        musicUrl.value.push(videoInfo);
      }
    }

    // 如果还有绘画中的订单，定时再次请求
    for (let item of videoList) {
      if (item.state === 0) {
        setTimeout(async () => {
          await getUserVideoList(openid, currentPage, requestedMusicIds, deng, musicUrl);
        }, 5000);
        return;
      }
    }

    // 如果没有更多数据，可以停止增加页码
    // if (videoList.length > 0 && videoList.length === 6) {
    //   currentPage++;
    // }

    console.log(currentPage);

  } catch (error) {
    console.log(error);
  }
};


/**
 * 生成视频接口
 * @param {string} openid 用户的唯一标识符
 * @param {string} prompt 描述词
 * @param {number} currentPage 当前页码
 * @param {Set} requestedMusicIds 已请求的 musicid 集合
 * @param {Array} deng 绘画中的订单列表
 * @param {Ref<Array>} musicUrl 响应式视频 URL 列表
 * @returns {Promise<void>}
 */
export const generateVideo = async (openid, prompt, currentPage, requestedMusicIds, deng, musicUrl) => {
  try {
    console.log("描述词：", prompt);

    const music = musicUrl.value.length;
    console.log("music:", music);

    const response = await axios.post(`/genceai/?s=/api/api/run/m/xz_aivideo/do/showaivideo/uniacid/10004&openid=${openid}&prompt=${prompt}`);
    console.log(response);
    if (response.data.message === "success") {
      // 显示视频加载框
      //videodeng.value = true;

      await getUserVideoList(openid, currentPage, requestedMusicIds, deng, musicUrl);

      // 检查是否有新的视频被加入到 musicUrl 中
      // 如果 deng 数组为空，则认为视频已经生成完毕
      if (musicUrl.value.length > music && deng.value.length === 0) {
        // 隐藏视频加载框
        //videodeng.value = false;
      }
    } else {
      ElMessage({ type: "error", message: response.data.data });
    }
  } catch (error) {
    console.log(error);
    ElMessage({ type: "error", message: "请求失败" });
  }
};


export const add = async (openid,prompt) => {
    // 重置页码
    const currentPage = 1; 
    
    console.log("描述词：", prompt.value);
    
    try {
      const response = await axios.post(`/genceai/?s=/api/api/run/m/xz_aivideo/do/showaivideo/uniacid/10004&openid=${openid}&prompt=${prompt}`);
  
      console.log(response);
      if (response.data.message === "success") {
        // 重新加载用户视频列表
        await getUserVideoList(openid, currentPage, prompt.value); // 调用获取视频列表的方法
      } else {
        ElMessage({ type: "error", message: response.data.data });
      }
    } catch (error) {
      console.log(error);
      ElMessage({ type: "error", message: "请求失败" });
    }
  };

